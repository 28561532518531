import React, { useState, useEffect } from 'react';
import TripList from './components/TripList';
import CreateTripModal from './components/CreateTripModal';
import EditTripModal from './components/EditTripModal';
import TripDetail from './components/TripDetail';
import { LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from './config';
import { db } from './firebase';
import { collection, onSnapshot, addDoc, doc, updateDoc, deleteDoc, getDocs } from 'firebase/firestore';

// Import global/common styles
import './styles/common.css';

function App() {
  const [trips, setTrips] = useState([]);
  const [selectedTripId, setSelectedTripId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editTrip, setEditTrip] = useState(null);
  const [selectedPlaceId, setSelectedPlaceId] = useState(null);

  const [isMobile, setIsMobile] = useState(false);
  const [mobileShowDetail, setMobileShowDetail] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "trips"), (snapshot) => {
      const tripData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTrips(tripData);
    });
    return () => unsub();
  }, []);

  const selectedTrip = trips.find((t) => t.id === selectedTripId);

  const handleCreateTrip = async (newTrip) => {
    const docRef = await addDoc(collection(db, "trips"), newTrip);
    setSelectedTripId(docRef.id); 
    setShowCreateModal(false);
    setSelectedPlaceId(null);
    if (isMobile) {
      setMobileShowDetail(true);
    }
  };

  const handleUpdateTrip = async (tripId, updatedTrip) => {
    const tripRef = doc(db, "trips", tripId);
    await updateDoc(tripRef, updatedTrip);
    setEditTrip(null);
  };

  const handleDeleteTrip = async (tripId) => {
    const placesCol = collection(db, "trips", tripId, "places");
    const placesSnap = await getDocs(placesCol);
    const batchDeletes = placesSnap.docs.map(d => deleteDoc(doc(db, "trips", tripId, "places", d.id)));
    await Promise.all(batchDeletes);
    await deleteDoc(doc(db, "trips", tripId));
    if (selectedTripId === tripId) setSelectedTripId(null);
    setSelectedPlaceId(null);
    if (isMobile) {
      setMobileShowDetail(false);
    }
  };

  const handleSelectTrip = (tripId) => {
    setSelectedTripId(tripId);
    setSelectedPlaceId(null);
    if (isMobile) {
      setMobileShowDetail(true);
    }
  };

  const handleMenuClick = () => {
    // On mobile detail view, going back to trip list
    setMobileShowDetail(false);
    setSelectedTripId(null);
    setSelectedPlaceId(null);
  };

  return (
    <LoadScript
      googleMapsApiKey={GOOGLE_MAPS_API_KEY}
      libraries={['places']}
    >
      <div className={`app-container ${isMobile ? 'mobile-view' : ''}`}>
        {(!isMobile || !mobileShowDetail) && (
          <div className="left-panel">
            <TripList
              trips={trips}
              selectedTripId={selectedTripId}
              onSelectTrip={handleSelectTrip}
              onCreateNewTrip={() => setShowCreateModal(true)}
              onEditTrip={(trip) => setEditTrip(trip)}
              onDeleteTrip={(tripId) => handleDeleteTrip(tripId)}
            />
          </div>
        )}
        {(!isMobile && selectedTrip) && (
          <div className="right-panel">
            <TripDetail 
              trip={selectedTrip} 
              selectedPlaceId={selectedPlaceId}
              onSelectPlace={(placeId) => setSelectedPlaceId(placeId)}
              onDeselectPlace={() => setSelectedPlaceId(null)}
              isMobile={isMobile}
              onMenuClick={handleMenuClick}
            />
          </div>
        )}
        {isMobile && mobileShowDetail && selectedTrip && (
          <div className="mobile-detail-panel">
            <TripDetail 
              trip={selectedTrip} 
              selectedPlaceId={selectedPlaceId}
              onSelectPlace={(placeId) => setSelectedPlaceId(placeId)}
              onDeselectPlace={() => setSelectedPlaceId(null)}
              isMobile={isMobile}
              onMenuClick={handleMenuClick}
            />
          </div>
        )}
        {showCreateModal && (
          <CreateTripModal
            onClose={() => setShowCreateModal(false)}
            onCreate={handleCreateTrip}
          />
        )}
        {editTrip && (
          <EditTripModal
            trip={editTrip}
            onClose={() => setEditTrip(null)}
            onSave={(updatedTrip) => handleUpdateTrip(editTrip.id, updatedTrip)}
          />
        )}
      </div>
    </LoadScript>
  );
}

export default App;
