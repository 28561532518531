import React, { useState, useRef } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import '../styles/Modals.css';

function EditTripModal({ trip, onClose, onSave }) {
  const [numPeople, setNumPeople] = useState(trip.numPeople || 1);
  const [locationInput, setLocationInput] = useState(trip.location || '');
  const [startDate, setStartDate] = useState(trip.startDate || '');
  const [endDate, setEndDate] = useState(trip.endDate || '');
  const autocompleteRef = useRef(null);

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry && place.geometry.location) {
        autocompleteRef.current.lat = place.geometry.location.lat();
        autocompleteRef.current.lng = place.geometry.location.lng();
        setLocationInput(place.formatted_address || place.name);
      }
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!locationInput) {
      alert('Location cannot be empty.');
      return;
    }
    let updated = {
      numPeople,
      location: locationInput,
      startDate: startDate || '',
      endDate: endDate || ''
    };
    if (autocompleteRef.current && autocompleteRef.current.lat !== undefined) {
      updated.lat = autocompleteRef.current.lat;
      updated.lng = autocompleteRef.current.lng;
    }
    await onSave(updated);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h3>Edit Trip</h3>
        </div>
        <form onSubmit={handleSave} className="modal-form">
          <div className="form-row">
            <label>Number of People:</label>
            <input
              type="number"
              min="1"
              value={numPeople}
              onChange={(e) => setNumPeople(parseInt(e.target.value, 10))}
            />
          </div>
          <div className="form-row">
            <label>Location:</label>
            <Autocomplete
              onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
              onPlaceChanged={onPlaceChanged}
            >
              <input
                type="text"
                value={locationInput}
                onChange={(e) => setLocationInput(e.target.value)}
                placeholder="Enter city or country"
              />
            </Autocomplete>
          </div>
          <div className="form-row">
            <label>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ fontFamily: 'sans-serif', fontSize: '0.9em' }}
            />
          </div>
          <div className="form-row">
            <label>End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ fontFamily: 'sans-serif', fontSize: '0.9em' }}
            />
          </div>
          <div className="modal-actions">
            <button type="button" onClick={onClose} className="cancel-btn">Cancel</button>
            <button type="submit" className="submit-btn">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditTripModal;
