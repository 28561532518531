import React, { useEffect, useRef, useMemo, useState } from 'react';
import { GoogleMap, Marker, Autocomplete } from '@react-google-maps/api';
import { db } from '../firebase';
import { collection, onSnapshot, addDoc, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { IoTrash, IoMenu, IoDocumentText, IoStar, IoStarOutline } from 'react-icons/io5';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import '../styles/TripDetail.css';

function getCategoryFromTypes(types) {
  if (!types) return 'Others';
  const lowerTypes = types.map(t => t.toLowerCase());
  if (lowerTypes.some(t => ['tourist_attraction', 'museum', 'park', 'zoo', 'art_gallery', 'point_of_interest'].includes(t))) return 'Attractions';
  if (lowerTypes.some(t => ['restaurant', 'bar', 'cafe', 'food'].includes(t))) return 'Food';
  if (lowerTypes.some(t => ['lodging', 'hotel'].includes(t))) return 'Accommodations';
  return 'Others';
}

const CATEGORIES = ["Attractions", "Food", "Accommodations", "Others"];

// Helper function to linkify URLs in notes
function linkify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);
  return parts.map((part, i) => {
    if (urlRegex.test(part)) {
      return <a key={i} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
    }
    return part;
  });
}

function TripDetail({ trip, selectedPlaceId, onSelectPlace, onDeselectPlace, isMobile, onMenuClick }) {
  const { id: tripId, lat, lng, startDate, endDate } = trip;

  const [places, setPlaces] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [collapsed, setCollapsed] = useState({
    Attractions: false,
    Food: false,
    Accommodations: false,
    Others: false
  });
  const [draggedPlaceId, setDraggedPlaceId] = useState(null);

  // Inline notes states
  const [notesPlaceId, setNotesPlaceId] = useState(null);
  const [tempNotes, setTempNotes] = useState('');
  const [isEditingNotes, setIsEditingNotes] = useState(false);

  // Itinerary state
  const [itinerary, setItinerary] = useState([]);
  const [showItinerary, setShowItinerary] = useState(false);

  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    const colRef = collection(db, "trips", tripId, "places");
    const unsub = onSnapshot(colRef, (snapshot) => {
      const placeData = snapshot.docs.map(d => ({ id: d.id, ...d.data() }));
      setPlaces(placeData);
    });
    return () => unsub();
  }, [tripId]);

  // Reset itinerary when trip changes
  useEffect(() => {
    setItinerary([]);
    setShowItinerary(false);
  }, [tripId]);

  const center = useMemo(() => ({ lat, lng }), [lat, lng]);
  const bounds = useMemo(() => {
    const delta = 0.1;
    return new window.google.maps.LatLngBounds(
      { lat: lat - delta, lng: lng - delta },
      { lat: lat + delta, lng: lng + delta }
    );
  }, [lat, lng]);

  const onPlaceChanged = async () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry && place.geometry.location) {
        const placeLat = place.geometry.location.lat();
        const placeLng = place.geometry.location.lng();
        const category = getCategoryFromTypes(place.types || []);
        await addDoc(collection(db, "trips", tripId, "places"), {
          name: place.name || place.formatted_address,
          lat: placeLat,
          lng: placeLng,
          category
        });
        setInputValue('');
      }
    }
  };

  const handleDeletePlace = async (placeId) => {
    await deleteDoc(doc(db, "trips", tripId, "places", placeId));
    if (selectedPlaceId === placeId) onSelectPlace(null);
  };

  const handleCategoryClick = (cat) => {
    setCollapsed(prev => ({ ...prev, [cat]: !prev[cat] }));
  };

  const categoryPlaces = useMemo(() => {
    const catMap = {
      "Attractions": [],
      "Food": [],
      "Accommodations": [],
      "Others": []
    };
    places.forEach(p => {
      catMap[p.category || 'Others'].push(p);
    });
    return catMap;
  }, [places]);

  const onPlaceClick = (place) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat: place.lat, lng: place.lng });
    }
    onSelectPlace(place.id);
  };

  useEffect(() => {
    if (selectedPlaceId) {
      const el = document.querySelector(`li[data-id="${selectedPlaceId}"]`);
      if (el && el.scrollIntoView) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedPlaceId]);

  const onDragStart = (e, placeId) => {
    setDraggedPlaceId(placeId);
    e.dataTransfer.effectAllowed = 'move';
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const onDropOnCategory = async (e, category) => {
    e.preventDefault();
    if (draggedPlaceId) {
      const placeRef = doc(db, "trips", tripId, "places", draggedPlaceId);
      await updateDoc(placeRef, { category });
      setDraggedPlaceId(null);
    }
  };

  const handleMapClick = () => {
    onSelectPlace(null);
  };

  const onMarkerClick = (place) => {
    onSelectPlace(place.id);
  };

  const getMarkerIcon = (placeId) => {
    if (selectedPlaceId === placeId) {
      return "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
    }
    return "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
  };

  const hasNotes = (place) => place.notes && place.notes.trim().length > 0;

  const toggleNotes = (place) => {
    if (notesPlaceId === place.id) {
      if (!isEditingNotes) {
        setNotesPlaceId(null);
      }
    } else {
      setNotesPlaceId(place.id);
      setTempNotes(place.notes || '');
      setIsEditingNotes(false);
    }
  };

  const saveNotes = async () => {
    if (!notesPlaceId) return;
    const placeRef = doc(db, "trips", tripId, "places", notesPlaceId);
    await updateDoc(placeRef, { notes: tempNotes });
    setIsEditingNotes(false);
  };

  const startEditingNotes = () => {
    setIsEditingNotes(true);
  };

  const toggleMustGo = async (place) => {
    const placeRef = doc(db, "trips", tripId, "places", place.id);
    await updateDoc(placeRef, { mustGo: !place.mustGo });
  };
  const isMustGo = (place) => place.mustGo === true;

  // Compute days between startDate and endDate
  function getTripDays(start, end) {
    if (!start || !end) return [];
    const startObj = new Date(start);
    const endObj = new Date(end);
    if (isNaN(startObj) || isNaN(endObj) || startObj > endObj) return [];
    const days = [];
    let current = new Date(startObj);
    while (current <= endObj) {
      days.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return days;
  }

  const handleCreateItinerary = () => {
    const days = getTripDays(startDate, endDate);
    if (days.length === 0) {
      // No valid days
      setItinerary([]);
      setShowItinerary(true);
      return;
    }

    const attractions = places.filter(p => p.category === 'Attractions');

    if (attractions.length === 0) {
      const emptyItinerary = days.map(d => ({ date: d.toISOString().split('T')[0], place: null }));
      setItinerary(emptyItinerary);
      setShowItinerary(true);
      return;
    }

    const mustGoAttractions = attractions.filter(p => p.mustGo);
    const otherAttractions = attractions.filter(p => !p.mustGo);

    const dayCount = days.length;

    let chosenAttractions = [];
    // Add must-go first
    chosenAttractions.push(...mustGoAttractions);

    // If we still need more to fill days
    if (chosenAttractions.length < dayCount) {
      // Shuffle otherAttractions
      const shuffled = [...otherAttractions];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      const remainingSlots = dayCount - chosenAttractions.length;
      chosenAttractions.push(...shuffled.slice(0, remainingSlots));
    }

    const finalItinerary = days.map((d, i) => {
      const place = i < chosenAttractions.length ? chosenAttractions[i] : null;
      return {
        date: d.toISOString().split('T')[0],
        place
      };
    });

    setItinerary(finalItinerary);
    setShowItinerary(true);
  };

  const handleHideItinerary = () => {
    setShowItinerary(false);
  };

  return (
    <div className="trip-detail-container">
      {isMobile && onMenuClick && (
        <button className="menu-btn" onClick={onMenuClick}>
          <IoMenu size={24} />
        </button>
      )}
      <div className="map-container">
        <GoogleMap
          onLoad={(map) => (mapRef.current = map)}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={center}
          zoom={12}
          onClick={handleMapClick}
          options={{ mapTypeControl: false, clickableIcons: false }}
        >
          {places.map((p) => (
            <Marker
              key={p.id}
              position={{ lat: p.lat, lng: p.lng }}
              onClick={() => onMarkerClick(p)}
              icon={getMarkerIcon(p.id)}
            />
          ))}
        </GoogleMap>
      </div>

      <div className={`places-container ${showItinerary ? 'with-itinerary' : ''}`}>
        <div className="places-list-container">
          <div className="autocomplete-container">
            <Autocomplete
              onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
              onPlaceChanged={onPlaceChanged}
              options={{
                bounds: bounds,
                strictBounds: false,
              }}
            >
              <input
                type="text"
                placeholder="Search for places"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{
                  width: '100%',
                  padding: '10px',
                  boxSizing: 'border-box',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  outline: 'none'
                }}
              />
            </Autocomplete>
          </div>
          <div className="category-list">
            {CATEGORIES.map(cat => {
              const catPlaces = categoryPlaces[cat];
              return (
                <div key={cat} className="category-section"
                     onDragOver={onDragOver}
                     onDrop={(e) => onDropOnCategory(e, cat)}>
                  <div
                    className="category-header"
                    onClick={() => handleCategoryClick(cat)}
                    onDragOver={onDragOver}
                    onDrop={(e) => onDropOnCategory(e, cat)}
                  >
                    <span>{cat}</span>
                    {collapsed[cat] ? <IoChevronUp /> : <IoChevronDown />}
                  </div>
                  {!collapsed[cat] && catPlaces.length > 0 && (
                    <ul className="places-list"
                        onDragOver={onDragOver}
                        onDrop={(e) => onDropOnCategory(e, cat)}>
                      {catPlaces.map((p) => {
                        const showNotes = (notesPlaceId === p.id);
                        return (
                          <li
                            key={p.id}
                            data-id={p.id}
                            className={selectedPlaceId === p.id ? 'selected-place' : ''}
                            draggable={true}
                            onDragStart={(e) => onDragStart(e, p.id)}
                            onClick={() => onPlaceClick(p)}
                          >
                            <div className="place-row-top">
                              <div className="place-info">
                                <div className="place-title-row">
                                  <div className="place-name">{p.name}</div>
                                  <button
                                    className={`icon-btn notes-icon ${hasNotes(p) ? 'has-notes' : 'no-notes'}`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleNotes(p);
                                    }}
                                  >
                                    <IoDocumentText />
                                  </button>
                                </div>
                                {(p.startDate || p.endDate) && (
                                  <div className="place-dates">
                                    {p.startDate && p.endDate ? `${p.startDate} - ${p.endDate}` : p.startDate ? `${p.startDate} -` : `- ${p.endDate}`}
                                  </div>
                                )}
                              </div>
                              <div className="place-actions">
                                <button className="icon-btn" onClick={(e) => { e.stopPropagation(); toggleMustGo(p); }}>
                                  {isMustGo(p) ? <IoStar style={{color: '#f5d742'}}/> : <IoStarOutline />}
                                </button>
                                <button className="icon-btn" onClick={(e) => { e.stopPropagation(); handleDeletePlace(p.id); }}>
                                  <IoTrash />
                                </button>
                              </div>
                            </div>
                            {showNotes && (
                              <div className="inline-notes" onClick={(e) => e.stopPropagation()}>
                                {isEditingNotes ? (
                                  <>
                                    <textarea
                                      className="notes-textarea"
                                      value={tempNotes}
                                      onChange={(e) => setTempNotes(e.target.value)}
                                    />
                                    <button className="save-notes-btn" onClick={saveNotes}>Save</button>
                                  </>
                                ) : (
                                  <div className="notes-content" onClick={startEditingNotes}>
                                    {tempNotes.trim().length > 0 ? linkify(tempNotes) : "No notes yet. Click to edit."}
                                  </div>
                                )}
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {!collapsed[cat] && catPlaces.length === 0 && (
                    <div className="no-places-msg">No places added</div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {showItinerary && (
          <div className="itinerary-container">
            <h3>Suggested Itinerary</h3>
            {itinerary.length === 0 ? (
              <div>No days or attractions available.</div>
            ) : (
              <ul className="itinerary-list">
                {itinerary.map((day, idx) => (
                  <li key={idx}>
                    <strong>{day.date}:</strong> {day.place ? day.place.name : 'No attraction assigned'}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        {!showItinerary ? (
          <button className="itinerary-toggle-btn" onClick={handleCreateItinerary}>Create itinerary</button>
        ) : (
          <button className="itinerary-toggle-btn" onClick={handleHideItinerary}>Hide itinerary</button>
        )}
      </div>
    </div>
  );
}

export default TripDetail;
