import React from 'react';
import { IoPencil, IoTrash, IoPeople } from 'react-icons/io5';
import '../styles/TripList.css';

function formatDate(dateStr) {
  if (!dateStr) return '';
  const parts = dateStr.split('-'); 
  if (parts.length === 3) {
    const [yyyy, mm, dd] = parts;
    return `${dd}/${mm}/${yyyy}`;
  }
  return dateStr;
}

function formatTripDates(startDate, endDate) {
  const sd = startDate ? formatDate(startDate) : '';
  const ed = endDate ? formatDate(endDate) : '';

  if (sd && ed) {
    return `${sd} - ${ed}`;
  } else if (sd && !ed) {
    return `${sd} -`;
  } else if (!sd && ed) {
    return `- ${ed}`;
  } else {
    return '';
  }
}

function TripList({ trips, selectedTripId, onSelectTrip, onCreateNewTrip, onEditTrip, onDeleteTrip }) {
  return (
    <div className="trip-list-container">
      <h2 style={{ margin: '20px 0', textAlign: 'center' }}>Trips</h2>
      <div className="trip-list">
        {trips.length === 0 ? (
          <div className="no-trips">No trips planned yet!</div>
        ) : (
          trips.map((trip) => {
            const dateStr = formatTripDates(trip.startDate, trip.endDate);
            return (
              <div
                key={trip.id}
                className={`trip-item ${selectedTripId === trip.id ? 'selected' : ''}`}
                onClick={() => onSelectTrip(trip.id)}
              >
                {trip.numPeople && (
                  <div className="people-count">
                    <IoPeople style={{ verticalAlign: 'middle', marginRight: '3px' }} />
                    {trip.numPeople}
                  </div>
                )}
                <div className="trip-info">
                  <div className="trip-name">{trip.location}</div>
                  {dateStr && <div className="trip-dates">{dateStr}</div>}
                </div>
                <div className="trip-icons" onClick={(e) => e.stopPropagation()}>
                  <button className="icon-btn" onClick={() => onEditTrip(trip)}>
                    <IoPencil />
                  </button>
                  <button className="icon-btn" onClick={() => onDeleteTrip(trip.id)}>
                    <IoTrash />
                  </button>
                </div>
              </div>
            );
          })
        )}
      </div>
      <button className="create-trip-btn" onClick={onCreateNewTrip}>Create new trip</button>
    </div>
  );
}

export default TripList;
